import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import JotformEmbed from "react-jotform-embed"
import styled from "styled-components"
import useWindowScroll from "../../../utils/useWindowScroll"
import TopControl from "../../common/TopControl"
import { RiMapPin2Fill, RiPhoneFill } from "react-icons/ri"
import { IoIosMail } from "react-icons/io"

const StyledDiv = styled.div`
  height: auto;
`

const HomeContactSection = () => {
  const data = useStaticQuery(graphql`
    query HomeContactSectionQuery {
      prismicHome {
        data {
          section_title
          section_text
          jotform_link
        }
      }
    }
  `)

  const [loadMain, setLoadMain] = useState(false)
  const doc = data.prismicHome
  const winScroll = useWindowScroll()

  useEffect(() => {
    if (winScroll && winScroll.scrollY > 300 && !loadMain) {
      setLoadMain(true)
    }
  }, [winScroll.scrollY])

  return (
    <>
      {loadMain ? (
        <div className="flex flex-col w-full py-2 sm:pt-12 sm:pb-12 px-4 items-center justify-center">
          <div className="w-full max-w-7xl relative flex flex-col">
            <TopControl
              showControl={false}
              sectionTitle={
                doc.data.section_title
                  ? doc.data.section_title
                  : "Section Title"
              }
              title="Get in touch with us"
            />
            <div className="w-full flex flex-col xl:flex-row mb-8">
              <div className="w-full xl:w-1/2 px-4 flex flex-col">
                <span className="mb-8">
                  {doc.data.section_text
                    ? doc.data.section_text
                    : "Section text here..."}
                </span>
                <div className="flex flex-col gap-y-4 font-medium">
                  <div className="flex gap-x-4 items-center">
                    <RiMapPin2Fill
                      className="text-site-yellow text-xl"
                      aria-label="Location"
                    />
                    <span>
                      470 Old Newport Boulevard, Newport Beach, CA 92663
                    </span>
                  </div>
                  <div className="flex gap-x-4 items-center">
                    <RiPhoneFill
                      className="text-site-yellow text-xl"
                      aria-label="telephone"
                    />
                    <a href="tel:949-645-5854">949-645-5854</a>
                  </div>
                  <div className="flex gap-x-4 items-center">
                    <IoIosMail
                      className="text-site-yellow text-xl"
                      aria-label="email"
                    />
                    <a href="info@bja-inc.com">info@bja-inc.com</a>
                  </div>
                </div>
              </div>
              {/* <div className="w-full xl:w-1/2 xl:px-4 pt-12 relative xl:-top-24 xl:shadow-formShadow bg-white rounded-md">
                <JotformEmbed
                  src={
                    doc.data.jotform_link
                      ? doc.data.jotform_link
                      : "https://form.jotform.com/232263590745055"
                  }
                />
              </div> */}
            </div>
          </div>
        </div>
      ) : (        
        <div>&nbsp;</div>
      )}
    </>
  )
}

export default HomeContactSection
