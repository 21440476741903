import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeaderLogoNew = () => {
  const data = useStaticQuery(graphql`
    query HeaderLogoNewQuery {
      prismicGeneralSettings {
        data {
          site_title
          logo {
            gatsbyImageData(width: 400, imgixParams: { auto: "false" })
          }
        }
      }
    }
  `)

  const doc = data.prismicGeneralSettings
  const image = getImage(doc.data.logo)

  return (
    <div>
      <h2>
        <Link to="/">
          <GatsbyImage
            image={image}
            alt={doc.data.site_title}
            imgStyle={{ marginLeft: "-25px" }}
            loading="eager"
          />
        </Link>
      </h2>
    </div>
  )
}

export default HeaderLogoNew
