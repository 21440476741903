import { graphql, navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import LayoutUpdate from "../../components/layout/layout-update"
import SEOnew from "../../components/seo-new"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/htmlSerializer"
import OtherPortfolioGallery from "./other-portfolio-gallery"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import useWindowSize from "../../utils/useWindowSize"
import { HiArrowLeft } from "react-icons/hi"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

import "./portfolio-details-styles.css"

// import required modules
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const PortfolioDetailsPage = ({ data }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [hidePic, setHidePic] = useState(false)
  const prismicPortfolioData = data.prismicPortfolio.data
  const screen = useWindowSize()
  const gallery = useMemo(() => {
    const images = []

    prismicPortfolioData.gallery.forEach(gallery => {
      if (gallery.image.fluid) {
        images.push(gallery.image.fluid.src)
        //images.push(gallery.image)
      }
    })

    return images
  }, [prismicPortfolioData])

  useEffect(() => {
    console.clear()
    function hide(e) {
      setHidePic(true)
      console.log(`Key Pressed Print Screen --->`)
      console.log(e)
    }

    function show() {
      setTimeout(() => {
        setHidePic(false)
      }, 1000)
    }
    function handleKeyUp(e) {
      console.log(e)
      if (e.key == "PrintScreen") {
        hide()
        show()
      }
    }

    function handleKeyDown(e) {
      if (e.key == "Meta" || e.key == "Alt") {
        hide()
      }
    }

    window.addEventListener("keyup", handleKeyUp)
    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("blur", hide)
    window.addEventListener("focus", show)
    return () => {
      window.removeEventListener("keyup", handleKeyUp)
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("blur", hide)
      window.removeEventListener("focus", show)
    }
  }, [])

  return (
    <LayoutUpdate isHome={true}>
      <SEOnew title={prismicPortfolioData.title} />
      <div className="w-full flex justify-center bg-site-gray">
        <div className="portfolio-details w-full">
          {gallery && gallery.length > 1 ? (
            <div className="mt-0 bg-site-gray">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                autoplay={{
                  delay: 12000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                onClick={swiper => {
                  swiper.autoplay.stop()
                }}
                onSlideNextTransitionEnd={swiper => {
                  swiper.autoplay.start()
                  swiper.autoplay.resume()
                }}
                onSlidePrevTransitionEnd={swiper => {
                  swiper.autoplay.start()
                  swiper.autoplay.resume()
                }}
                speed={800}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Autoplay, Thumbs]}
                className="portfolio-swiper"
              >
                {gallery.map((img, index) => {
                  const image = getImage(img)
                  const bgImage = convertToBgImage(image)
                  return (
                    <SwiperSlide
                      key={`portfolio-details-gallery-${index}`}
                      className="portfolio-swiper-slide relative w-100 h-100 bg-gray-500"
                    >
                      <img
                        src={img}
                        loading="lazy"
                        alt={prismicPortfolioData.title}
                        style={{ width: "auto", height: "100%" }}
                      />
                      <StaticImage
                        id="cover"
                        src="../../images/blank.png"
                        quality={95}
                        placeholder="DOMINANT_COLOR"
                        formats={["AUTO", "WEBP"]}
                        alt={`Portfolio${prismicPortfolioData.title}`}
                        className="w-full h-full absolute z-5 top-0 left-0"
                        objectFit="cover"
                      />
                      {/* <BackgroundImage
                        Tag="section"
                        {...bgImage}
                        preserveStackingContext
                        className="w-auto h-full restrict"
                        style={hidePic ? { backgroundColor: "black" } : {}}
                      >
                        <StaticImage
                          id="cover"
                          src="../../images/blank.png"
                          quality={95}
                          placeholder="DOMINANT_COLOR"
                          formats={["AUTO", "WEBP"]}
                          alt={`Portfolio${prismicPortfolioData.title}`}
                          className="w-full h-full absolute z-5 top-0 left-0"
                          objectFit="cover"
                        />
                        &nbsp;
                      </BackgroundImage> */}
                      <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <div
                className="py-2"
                style={{ height: screen.width <= 640 ? "10vh" : "20vh" }}
              >
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={screen.width <= 640 ? 3 : 6}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="portfolio-thumb-swiper"
                >
                  {gallery.map((img, index) => {
                    const image = getImage(img)
                    const bgImage = convertToBgImage(image)
                    return (
                      <SwiperSlide
                        key={`portfolio-details-gallery-${index}`}
                        className="portfolio-swiper-slide"
                      >
                        <img
                          src={img}
                          alt={prismicPortfolioData.title}
                          loading="lazy"
                        />
                        <StaticImage
                          id="cover"
                          src="../../images/blank.png"
                          quality={95}
                          placeholder="DOMINANT_COLOR"
                          formats={["AUTO", "WEBP"]}
                          alt={`Portfolio${prismicPortfolioData.title}`}
                          className="w-full h-full absolute z-5 top-0 left-0"
                          objectFit="cover"
                        />
                        {/* <BackgroundImage
                          Tag="section"
                          {...bgImage}
                          preserveStackingContext
                          className="w-auto h-full restrict"
                          style={hidePic ? { backgroundColor: "black" } : {}}
                        >
                          <StaticImage
                            id="cover"
                            src="../../images/blank2.png"
                            quality={95}
                            placeholder="DOMINANT_COLOR"
                            formats={["AUTO", "WEBP"]}
                            alt={`Portfolio${prismicPortfolioData.title}`}
                            className="w-full h-full absolute z-5 top-0 left-0"
                            objectFit="cover"
                          />
                          &nbsp;
                        </BackgroundImage> */}
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </div>
          ) : (
            <div
              className="w-full flex items-center justify-center mt-32"
              style={{ height: "80vh" }}
            >
              <GatsbyImage
                image={getImage(prismicPortfolioData.preview_image)}
                alt={prismicPortfolioData.title}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                imgStyle={{
                  height: "100% !important",
                  width: "auto  !important",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="py-24 bg-site-gray w-full flex flex-col text-white justify-center items-center px-4">
        <div className="w-full max-w-7xl flex flex-col gap-y-8">
          <div className="text-site-yellow text-3xl">
            {prismicPortfolioData.title}
            {prismicPortfolioData.location && (
              <p className="text-gray-400 text-base md:text-lg">
                {prismicPortfolioData.location}
              </p>
            )}
            <div className="portfolio-details mt-6">
              <PrismicRichText
                field={prismicPortfolioData.project_details_content.richText}
                components={htmlSerializer}
              />
            </div>
          </div>
          <div className="text-gray-400 text-xl">
            {data.prismicPortfolio.tags[0]}
          </div>
          <div
            className="flex items-center gap-x-2 cursor-pointer"
            onClick={() => {
              navigate("/portfolios")
            }}
          >
            <HiArrowLeft />
            <p className="text-sm">Back to Portfolio</p>
          </div>
        </div>
      </div>
      {data.prismicPortfolio.first_publication_date ? (
        <OtherPortfolioGallery
          uid={data.prismicPortfolio.uid}
          tag={data.prismicPortfolio.tags[0]}
        />
      ) : null}
    </LayoutUpdate>
  )
}

export default withPrismicPreview(PortfolioDetailsPage)

export const portfolioTemplateQuery = graphql`
  query($id: String!) {
    prismicPortfolio(id: { eq: $id }) {
      _previewable
      uid
      tags
      first_publication_date
      data {
        title
        location
        preview_image {
          gatsbyImageData(imgixParams: { auto: "false" })
        }
        gallery {
          image {
            fluid(imgixParams: { auto: "false" }) {
              src
            }
            gatsbyImageData
          }
        }
        project_details_content {
          richText
        }
      }
    }
  }
`
