import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaPinterestSquare,
  FaLinkedin,
  FaInstagramSquare,
} from "react-icons/fa"
import { IoIosMail } from "react-icons/io"
import FooterContainer from "./FooterContainer"

const menuItems =
  "text-white text-center text-sm hover:text-site-yellow hover:underline"
const menuContact =
  "text-white text-center text-sm xl:text-xs hover:text-site-yellow hover:underline"

const FooterNewUpdate = () => {
  const data = useStaticQuery(graphql`
    query FooterNewUpdateQuery {
      prismicGeneralSettings {
        data {
          first_static_menu_name
          first_static_menu_link {
            uid
          }
          second_static_menu_name
          second_static_menu_link {
            uid
          }
          third_static_menu_name
          third_static_menu_link {
            uid
          }
          fourth_static_menu_name
          fourth_static_menu_link {
            uid
          }
          fifth_static_menu_name
          fifth_static_menu_link {
            uid
          }
          button_menu_name
          button_menu_link {
            uid
          }
          footer_title
          facebook_link
          twitter_link
          pinterest_link
          linkedin_link
          instagram_link
          first_org_logo {
            gatsbyImageData(width: 155)
            alt
          }
          first_org_link
          second_org_logo {
            gatsbyImageData(width: 62)
            alt
          }
          second_org_link
          third_org_logo {
            gatsbyImageData(width: 155)
            alt
          }
          third_org_link
          houzz_badge_link
          houzz_badges {
            houzz_badge_image {
              gatsbyImageData(width: 62)
              alt
            }
          }
        }
      }

      prismicContactPage {
        data {
          company_name
          address
          google_map_link
          phone_number
          email
        }
      }
      imageSharp(fluid: { originalName: { eq: "footer.png" } }) {
        gatsbyImageData(quality: 100)
      }
    }
  `)

  const doc = data.prismicGeneralSettings
  const contact = data.prismicContactPage
  const badges = doc.data.houzz_badges
  const firstImageLogo = getImage(doc.data.first_org_logo)
  const secondImageLogo = getImage(doc.data.second_org_logo)
  const thirdImageLogo = getImage(doc.data.third_org_logo)

  return (
    <>      
      <FooterContainer>
        <footer className="w-full max-w-screen-xl flex flex-col justify-center items-center mx-auto">
          <div className="w-full mb-6">
            <h2 className="text-3xl text-white text-center">
              {doc.data.footer_title ? doc.data.footer_title : "Footer Title"}
            </h2>
          </div>

          <div className="flex items-center space-x-3">
            <a
              href={
                doc.data.facebook_link
                  ? doc.data.facebook_link
                  : "https://www.facebook.com"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare
                className="w-8 h-8 text-white hover:text-site-yellow"
                aria-label="Facebook"
              />
            </a>

            <a
              href={
                doc.data.linkedin_link
                  ? doc.data.linkedin_link
                  : "https://www.linkedin.com"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                className="w-8 h-8 text-white hover:text-site-yellow"
                aria-label="Linkedin"
              />
            </a>

            <a
              href={
                doc.data.instagram_link
                  ? doc.data.instagram_link
                  : "https://www.instagram.com"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagramSquare
                className="w-8 h-8 text-white hover:text-site-yellow"
                aria-label="Instragram"
              />
            </a>
          </div>

          <div className="w-full flex items-center justify-center space-x-3 mt-12 mx-auto lg:hidden">
            <a
              href={
                doc.data.first_org_link
                  ? doc.data.first_org_link
                  : "https://ases.org"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage image={firstImageLogo} alt={firstImageLogo.alt} />
            </a>

            {secondImageLogo && (
              <a
                href={
                  doc.data.second_org_link
                    ? doc.data.second_org_link
                    : "http://usgbc-oc.org"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <GatsbyImage
                  image={secondImageLogo}
                  alt={secondImageLogo.alt}
                />
              </a>
            )}

            <a
              href={
                doc.data.third_org_link
                  ? doc.data.third_org_link
                  : "https://www.aia.org"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={thirdImageLogo}
                alt={thirdImageLogo.alt}
                class="ml-4"
              />
            </a>
          </div>

          <div className="flex gap-2 lg:gap-8 mt-10">
            <a
              className="hidden lg:block pr-4"
              href={
                doc.data.first_org_link
                  ? doc.data.first_org_link
                  : "https://ases.org"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage image={firstImageLogo} alt={firstImageLogo.alt} />
            </a>
            {badges.map((badge, index) => {
              const badgeImage = getImage(badge.houzz_badge_image)
              return (
                <a
                  key={`badge-${index}-index`}
                  href={
                    doc.data.houzz_badge_link
                      ? doc.data.houzz_badge_link
                      : "http://www.houzz.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  index={index}
                >
                  <GatsbyImage image={badgeImage} alt={badgeImage.alt} />
                </a>
              )
            })}
            <a
              className="hidden lg:block"
              href={
                doc.data.third_org_link
                  ? doc.data.third_org_link
                  : "https://www.aia.org"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={thirdImageLogo}
                alt={thirdImageLogo.alt}
                class="ml-4"
              />
            </a>
          </div>

          <div className="mt-16 flex flex-col xl:flex-row items-center md:space-x-4">
            <div className="w-full flex flex-col md:flex-row md:space-x-4 items-center justify-center">
              <div className="flex flex-row items-center space-x-6 mb-6 md:mb-0">
                <Link to="/">
                  <p className={menuItems}>Home</p>
                </Link>

                {doc.data.first_static_menu_link ? (
                  <Link
                    to={
                      doc.data.first_static_menu_link.uid
                        ? `/${doc.data.first_static_menu_link.uid}`
                        : "/"
                    }
                  >
                    <p className={menuItems}>
                      {doc.data.first_static_menu_name
                        ? doc.data.first_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                ) : (
                  <Link to="/">
                    <p className={menuItems}>
                      {doc.data.first_static_menu_name
                        ? doc.data.first_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                )}
              </div>

              <div className="flex flex-row items-center space-x-6 mb-6 md:mb-0">
                {doc.data.second_static_menu_link ? (
                  <Link
                    to={
                      doc.data.second_static_menu_link.uid
                        ? `/${doc.data.second_static_menu_link.uid}`
                        : "/"
                    }
                  >
                    <p className={menuItems}>
                      {doc.data.second_static_menu_name
                        ? doc.data.second_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                ) : (
                  <Link to="/">
                    <p className={menuItems}>
                      {doc.data.second_static_menu_name
                        ? doc.data.second_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                )}

                {doc.data.third_static_menu_link ? (
                  <Link
                    to={
                      doc.data.third_static_menu_link.uid
                        ? `/${doc.data.third_static_menu_link.uid}`
                        : "/"
                    }
                  >
                    <p className={menuItems}>
                      {doc.data.third_static_menu_name
                        ? doc.data.third_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                ) : (
                  <Link to="/">
                    <p className={menuItems}>
                      {doc.data.third_static_menu_name
                        ? doc.data.third_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                )}

                {doc.data.fourth_static_menu_link ? (
                  <Link
                    to={
                      doc.data.fourth_static_menu_link.uid
                        ? `/${doc.data.fourth_static_menu_link.uid}`
                        : "/"
                    }
                  >
                    <p className={menuItems}>
                      {doc.data.fourth_static_menu_name
                        ? doc.data.fourth_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                ) : (
                  <Link to="/">
                    <p className={menuItems}>
                      {doc.data.fourth_static_menu_name
                        ? doc.data.fourth_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                )}

                {doc.data.fifth_static_menu_link ? (
                  <Link
                    to={
                      doc.data.fifth_static_menu_link.uid
                        ? `/${doc.data.fifth_static_menu_link.uid}`
                        : "/"
                    }
                  >
                    <p className={menuItems}>
                      {doc.data.fifth_static_menu_name
                        ? doc.data.fifth_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                ) : (
                  <Link to="/">
                    <p className={menuItems}>
                      {doc.data.fifth_static_menu_name
                        ? doc.data.fifth_static_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                )}
              </div>

              <div className="flex flex-row items-center space-x-6 mb-6 md:mb-0">
                <Link to="/careers">
                  <p className={menuItems}>Careers</p>
                </Link>

                {doc.data.button_menu_link ? (
                  <Link
                    to={
                      doc.data.button_menu_link.uid
                        ? `/${doc.data.button_menu_link.uid}`
                        : "/"
                    }
                  >
                    <p className={menuItems}>
                      {doc.data.button_menu_name
                        ? doc.data.button_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                ) : (
                  <Link to="/">
                    <p className={menuItems}>
                      {doc.data.button_menu_name
                        ? doc.data.button_menu_name
                        : "Menu"}
                    </p>
                  </Link>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row  md:space-x-4 items-center justify-center">
              <div className="flex items-center justify-center border-t md:border-t-0 xl:border-l xl:border-gray-500 pt-6 xl:pt-0 xl:pl-4 mb-6 xl:mb-0">
                <a
                  href={
                    contact.data.google_map_link
                      ? contact.data.google_map_link
                      : "https://www.google.com/maps"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={menuContact}>
                    {contact.data.address
                      ? contact.data.address
                      : "Office Address"}
                  </p>
                </a>
              </div>

              <div className="flex items-center justify-center border-t md:border-t-0 border-b md:border-b-0 xl:border-l xl:border-r xl:border-gray-500 pt-6 xl:pt-0 xl:pl-4 pb-6 xl:pb-0 xl:pr-4">
                <a
                  href={`tel:${
                    contact.data.phone_number
                      ? contact.data.phone_number
                      : "123-456-7890"
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className={menuContact}>
                    {contact.data.phone_number
                      ? contact.data.phone_number
                      : "123-456-7890"}
                  </p>
                </a>
              </div>

              <div className="mt-6 xl:mt-0">
                <a
                  href={`mailto:${contact.data.email}`}
                  className={menuItems}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoIosMail
                    className="w-8 h-8 xl:w-4 xl:h-4 text-white hover:text-site-yellow"
                    aria-label="email"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <p className="text-white text-center md:text-left text-sm">
              ©{new Date().getFullYear()}{" "}
              <Link to="/">Brion Jeannette Architecture</Link>.
              <br className="md:hidden" /> <br className="md:hidden" /> All
              Rights Reserved Legal.
              <br className="md:hidden" /> <br className="md:hidden" />{" "}
              Website by{" "}
              <a
                href="https://www.fastweb.dev"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-site-yellow hover:underline"
              >
                fastweb.dev
              </a>
            </p>
          </div>
        </footer>
      </FooterContainer>
    </>
  )
}

export default FooterNewUpdate
