import { graphql, navigate, useStaticQuery } from "gatsby"
import React, { useMemo, useState } from "react"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import LayoutUpdate from "../../components/layout/layout-update"
import SEOnew from "../../components/seo-new"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/htmlSerializer"
import SlideContent from "../../components/sections/HomeFeaturedSection/SlideContent"
import useWindowSize from "../../utils/useWindowSize"
import { HiArrowLeft } from "react-icons/hi"
import "./blog-details-styles.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogDetailsPage = ({ data }) => {
  const prismicBlogData = data.prismicBlog.data
  const screen = useWindowSize()
  const image = getImage(prismicBlogData.preview_image)

  return (    
    <LayoutUpdate isHome={true}>
      <SEOnew title={prismicBlogData.title} />
      <div className="w-full flex flex-col items-center justify-center px-4">
        <div className="w-full max-w-5xl pt-12 pb-2 md:pb-8 flex items-center justify-center flex-col gap-8">
          <div className="text-site-gray text-2xl lg:text-3xl text-center flex flex-col">
            <span>{prismicBlogData.title}</span>
          </div>
          <GatsbyImage
            image={image}
            className="w-full md:w-2/3 px-4 md:px-0"
            alt={image.alt ? image.alt : ""}
          />
          {prismicBlogData.content.richText.length > 0 && (
            <div className="blog-details w-full">
              <PrismicRichText
                field={prismicBlogData.content.richText}
                components={htmlSerializer}
              />
            </div>
          )}
        </div>
        <div className="w-full flex flex-col text-site-gray justify-center items-center pb-24">
          <div className="w-full max-w-5xl flex flex-col gap-y-8">
            <div className="text-xl">{data.prismicBlog.tags[0]}</div>
            <div
              className="flex items-center gap-x-2 cursor-pointer"
              onClick={() => {
                navigate("/blog")
              }}
            >
              <HiArrowLeft />
              <p className="text-sm">Back to Blog</p>
            </div>
          </div>
        </div>
      </div>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(BlogDetailsPage)

export const BlogDetailsPageQuery = graphql`
  query($id: String!) {
    prismicBlog(id: { eq: $id }) {
      _previewable
      uid
      tags
      data {
        title
        content {
          richText
        }
        published_date
        preview_image {
          alt
          gatsbyImageData
        }
      }
    }
  }
`
