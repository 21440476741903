import React from "react"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import { MenuIcon } from "@heroicons/react/outline"
import styled from "styled-components"

import HeaderLogoNew from "./header-logo-new"
import MenuItems from "./menu-items"

const MobileDrawerNew = loadable(
  () => pMinDelay(import("./mobile-drawer-new"), 2000),
  {
    fallback: (
      <div className="w-2/3 xl:hidden flex justify-end">
        <button className="text-site-gray focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent mr-2 p-0">
          <span className="sr-only">Open panel</span>
          <MenuIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>
    ),
  }
)

const StyledDiv = styled.div``

const menuItems =
  "text-xs text-white hover:text-site-yellow font-semibold uppercase tracking-wider hover:underline"
const menuItemsPop =
  "text-sm text-white hover:text-site-yellow font-semibold uppercase tracking-wider hover:underline"
const menuItemsButton =
  "bg-site-yellow hover:bg-white text-lg text-white hover:text-site-yellow font-semibold uppercase tracking-wider px-4 py-2"

const HeaderNew = ({ isHome }) => {

  return (
    <StyledDiv
      className="fixed top-0 left-0 bg-transparent w-full xl:px-4 z-50 bg-site-dark-gray"
    >
      <header className="w-full max-w-screen-2xl mx-auto">
        <nav className="flex items-center justify-between mx-auto">
          <div className="lg:w-32 xl:w-auto flex items-center py-2 sm:py-0 lg:min-w-400">
            <HeaderLogoNew />
          </div>
          <MenuItems />

          <MobileDrawerNew />
        </nav>
      </header>
    </StyledDiv>
  )
}

export default HeaderNew
