import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const menuItems =
  "font-sinkin text-xs text-white hover:text-site-yellow uppercase tracking-wider hover:underline"
const menuItemsDrop =
  "font-sinkin text-xs text-white hover:text-site-yellow uppercase tracking-wider"
const menuItemsPop =
  "font-sinkin text-sm text-white hover:text-site-yellow uppercase tracking-wider hover:underline"
const menuItemsButton =
  "font-sinkin bg-site-yellow hover:bg-white text-sm text-white hover:text-site-yellow font-medium uppercase tracking-wider rounded-sm py-2 px-4"
const MenuItems = () => {
  const data = useStaticQuery(graphql`
    query MenuItemsQuery {
      prismicGeneralSettings {
        data {
          about_menu
          about_menu_link {
            uid
          }
          blog_menu
          blog_menu_link {
            uid
          }
          first_static_menu_name
          first_static_menu_link {
            uid
          }
          second_static_menu_name
          second_static_menu_link {
            uid
          }
          third_static_menu_name
          third_static_menu_link {
            uid
          }
          fourth_static_menu_name
          fourth_static_menu_link {
            uid
          }
          fifth_static_menu_name
          fifth_static_menu_link {
            uid
          }
          button_menu_name
          button_menu_link {
            uid
          }
        }
      }
    }
  `)

  const doc = data.prismicGeneralSettings

  return (
    <ul className="hidden xl:flex flex-grow items-center justify-center flex-wrap space-x-8 2xl:space-x-12">
      {doc.data.about_menu_link ? (
        <Link
          to={
            doc.data.about_menu_link.uid
              ? `/${doc.data.about_menu_link.uid}`
              : "/about-us-overview"
          }
        >
          <li className={menuItems}>
            {doc.data.about_menu
              ? doc.data.about_menu
              : "About"}
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            {doc.data.about_menu
              ? doc.data.about_menu
              : "About"}
          </li>
        </Link>
      )}

      {doc.data.first_static_menu_link ? (
        <Link
          to={
            doc.data.first_static_menu_link.uid
              ? `/${doc.data.first_static_menu_link.uid}`
              : "/"
          }
        >
          <li className={menuItems}>
            {doc.data.first_static_menu_name
              ? doc.data.first_static_menu_name
              : "Menu"}
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            {doc.data.first_static_menu_name
              ? doc.data.first_static_menu_name
              : "Menu"}
          </li>
        </Link>
      )}

      {doc.data.second_static_menu_link ? (
        <Link
          to={
            doc.data.second_static_menu_link.uid
              ? `/${doc.data.second_static_menu_link.uid}`
              : "/"
          }
        >
          <li className={menuItems}>
            {doc.data.second_static_menu_name
              ? doc.data.second_static_menu_name
              : "Menu"}
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            {doc.data.second_static_menu_name
              ? doc.data.second_static_menu_name
              : "Menu"}
          </li>
        </Link>
      )}

      {doc.data.blog_menu_link ? (
        <Link
          to={
            doc.data.blog_menu_link.uid
              ? `/${doc.data.blog_menu_link.uid}`
              : "/blog"
          }
        >
          <li className={menuItems}>
            {doc.data.blog_menu
              ? doc.data.blog_menu
              : "Blog"}
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            {doc.data.about_menu
              ? doc.data.about_menu
              : "Menu"}
          </li>
        </Link>
      )}

      {doc.data.third_static_menu_link ? (
        <Link
          to={
            doc.data.third_static_menu_link.uid
              ? `/${doc.data.third_static_menu_link.uid}`
              : "/"
          }
        >
          <li className={menuItems}>
            {doc.data.third_static_menu_name
              ? doc.data.third_static_menu_name
              : "Menu"}
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            {doc.data.third_static_menu_name
              ? doc.data.third_static_menu_name
              : "Menu"}
          </li>
        </Link>
      )}

      {doc.data.fourth_static_menu_link ? (
        <Link
          to={
            doc.data.fourth_static_menu_link.uid
              ? `/${doc.data.fourth_static_menu_link.uid}`
              : "/"
          }
        >
          <li className={menuItems}>
            {doc.data.fourth_static_menu_name
              ? doc.data.fourth_static_menu_name
              : "Menu"}
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            {doc.data.fourth_static_menu_name
              ? doc.data.fourth_static_menu_name
              : "Menu"}
          </li>
        </Link>
      )}

      {doc.data.fifth_static_menu_link ? (
        <Link
          to={
            doc.data.fifth_static_menu_link.uid
              ? `/${doc.data.fifth_static_menu_link.uid}`
              : "/"
          }
        >
          <li className={menuItems}>
            {doc.data.fifth_static_menu_name
              ? doc.data.fifth_static_menu_name
              : "Menu"}
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            {doc.data.fifth_static_menu_name
              ? doc.data.fifth_static_menu_name
              : "Menu"}
          </li>
        </Link>
      )}

      {doc.data.button_menu_link ? (
        <Link
          to={
            doc.data.button_menu_link.uid
              ? `/${doc.data.button_menu_link.uid}`
              : "/"
          }
        >
          <li className={menuItems}>
            <button className={menuItemsButton}>
              {doc.data.button_menu_name ? doc.data.button_menu_name : "Menu"}
            </button>
          </li>
        </Link>
      ) : (
        <Link to="/">
          <li className={menuItems}>
            <button className={menuItemsButton}>
              {doc.data.button_menu_name ? doc.data.button_menu_name : "Menu"}
            </button>
          </li>
        </Link>
      )}
    </ul>
  )
}

export default MenuItems
