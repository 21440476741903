import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React from "react"

const FooterContainer = ({ children }) => {
  const data = useStaticQuery(graphql`
    query FooterContainerQuery {
      allImageSharp(
        filter: {
          fluid: { originalName: { in: ["footer.png", "footer-mobile.png"] } }
        }
      ) {
        edges {
          node {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  `)

  const footerImage = getImage(data.allImageSharp.edges[1].node)
  const bgImage = convertToBgImage(footerImage)

  const footerImageMobile = getImage(data.allImageSharp.edges[0].node)
  const bgImageMobile = convertToBgImage(footerImageMobile)

  return (
    <>
      <div className="hidden 2xl:flex">
        <BackgroundImage
          className="relative w-full bg-cover bg-right-top"
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
        >
          <div className="w-full px-4 pb-14 pt-20">
            {children}
          </div>
        </BackgroundImage>
      </div>
      <div className="flex 2xl:hidden ">
        <BackgroundImage
          className="w-full h-full relative bg-cover bg-right-top"
          // Spread bgImage into BackgroundImage:
          {...bgImageMobile}
          preserveStackingContext
        >
          <div className="w-full px-4 pb-14 pt-14">{children}</div>
        </BackgroundImage>
      </div>
    </>
  )
}

export default FooterContainer
