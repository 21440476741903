import React, { useState } from "react"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"

const TopControl = ({
  title = "Title",
  sectionTitle = "",
  showControl = true,
  onNext,
  onPrevious,
}) => {
  const [prevPushed, setPrevPushed] = useState(false)
  const [nextPushed, setNextPushed] = useState(false)

  return (
    <div className="w-full flex flex-col sm:flex-row py-4 px-4 relative">
      <div className="w-full sm:w-5/12 flex flex-col">
        <div className="text-site-yellow font-semibold uppercase text-xs sm:text-sm">
          {sectionTitle}
        </div>
        <h2 className="text-2xl sm:text-4xl font-semibold relative feature-slide-title">
          <span>{title}</span>
        </h2>
      </div>
      <div
        className={`w-full sm:w-1/2 flex justify-end items-center text-4xl gap-x-4 text-site-yellow absolute sm:static -bottom-10 right-5 z-10 ${
          !showControl ? "hidden" : ""
        }`}
      >
        {onPrevious && (
          <button
            className={`rounded-full bg-white p-2 cursor-pointer text-3xl sm:text-4xl ${
              prevPushed ? "push-button" : ""
            }`}
            style={{ boxShadow: "2px 2px 30px 2px #AAAAAA" }}
            onClick={() => {
              setPrevPushed(true)
              onPrevious()
            }}
            onAnimationEnd={() => setPrevPushed(false)}
          >
            <BsArrowLeft />
          </button>
        )}
        {onNext && (
          <button
            className={`rounded-full bg-white p-2 cursor-pointer text-3xl sm:text-4xl ${
              nextPushed ? "push-button" : ""
            }`}
            style={{ boxShadow: "2px 2px 30px 2px #AAAAAA" }}
            onClick={() => {
              setNextPushed(true)
              onNext()
            }}
            onAnimationEnd={() => setNextPushed(false)}
          >
            <BsArrowRight />
          </button>
        )}
      </div>
    </div>
  )
}

export default TopControl
