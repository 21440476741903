import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const SlideContent = ({ item }) => {
  const image = getImage(item.image)
  const bgImage = convertToBgImage(image) 
  return (
    <div>
      <div
        className="w-full flex flex-col text-center rounded-xl h-full"
        style={{ boxShadow: "02px 2px 20px 2px #AAAAAA" }}
      >
        <div className="w-full h-full rounded-xl overflow-hidden pt-2">
          <BackgroundImage
            Tag="section"
            {...bgImage}
            preserveStackingContext
            className="h-320px w-full"
          >
            &nbsp;
          </BackgroundImage>
          <div className="pt-8 px-4 min-h-150">
            <div className="px-2 text-xl font-medium text-black">
              {item.title}
            </div>
            <div className="px-2 text-gray-400">{item.location}</div>
          </div>
        </div>
      </div>
      <a
        href={`/portfolios-details/${item.uid}`}
        className="w-full h-full absolute left-0 top-0 cursor-pointer opacity-0"
      >
        &nbsp;
      </a>
    </div>
  )
}

export default SlideContent
