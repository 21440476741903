import { graphql, useStaticQuery, Link } from "gatsby"
import React from "react"
import HomeContactSection from "../ContactSection/home-contact-section"
import HomeContactSectionNew from "../ContactSection/home-contact-section-new"

const ContactUsCompact = () => {
  const data = useStaticQuery(graphql`
    query HomeContactSectionCompactQuery {
      prismicHome {
        data {
          section_title
          section_text
        }
      }
    }
  `)
  const { section_text, section_title } = data.prismicHome.data

  return (
    <HomeContactSectionNew />
    // <div className="flex w-full flex-col py-24 bg-site-gray text-white items-center gap-y-6">
    //   <h2 className="text-3xl text-white text-center">
    //     {section_title ? section_title : "Section Title"}
    //   </h2>
    //   <p className="text-lg text-gray-500 text-center mt-6">
    //     {section_text ? section_text : "Section text here..."}
    //   </p>
    //   <Link to="/contact" className="bg-black py-4 px-8">
    //     contact us
    //   </Link>
    // </div>
  )
}

export default ContactUsCompact
