import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as containerStyles from "./container.module.css"

const CommitmentSignature = () => {
  return (
    <div className="bg-site-dark-gray">
      <div className="w-full flex justify-center items-center">
        <div className="flex flex-grow items-center justify-center pt-8 lg:pt-12 pb-4">
          <div className="w-full max-w-7xl text-2xl lg:text-3xl text-site-yellow px-4 2xl:px-14">
            {/* <div className="relative 2xl:absolute -top-2 2xl:top-0 2xl:-left-4">
              <StaticImage src="../../../images/backgrounds/quote.png" />
            </div> */}
            Our goal is to create a home that our clients hate to leave and are
            eager to return to; our objective is to enhance and improve the
            quality of their lives.
            <StaticImage
              src="../../../images/logos/brions_sign.png"
              height={120}
              width={180}
              objectFit="contain"
              className="-mt-6"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommitmentSignature
