import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import { RiMapPin2Fill, RiPhoneFill } from "react-icons/ri"
import { IoIosMail } from "react-icons/io"

const StyledDiv = styled.div`
  height: auto;
`

const HomeContactSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query HomeContactSectionUpdateQuery {
      prismicHome {
        data {
          section_title
          section_text
          jotform_link
        }
      }
    }
  `)

  const doc = data.prismicHome  

  return (
    <>
      <div className="flex flex-col w-full py-2 pt-8 sm:pt-12 sm:pb-12 px-4 items-center justify-center">
        <div className="w-full max-w-7xl relative flex flex-col items-center justify-center">
          <div className="w-full sm:w-5/12 flex flex-col">
            <h2 className="text-2xl sm:text-4xl font-semibold relative mb-6 text-center">
              <span>Get in touch with us</span>
            </h2>
          </div>

          <div className="w-full flex flex-col items-center justify-center mb-8">
            <div className="w-full xl:w-1/2 px-4 flex flex-col items-center justify-center">
              <span className="mb-8 text-center">
                {doc.data.section_text
                  ? doc.data.section_text
                  : "Section text here..."}
              </span>
              <div className="flex flex-col gap-y-4 font-medium">
                <div className="flex items-center justify-center mb-4">
                  <Link to="/contact">
                    <button className="font-lato bg-site-yellow hover:bg-white text-lg text-white hover:text-site-yellow font-semibold uppercase tracking-wider px-4 pt-1 pb-1">
                      Contact Us
                    </button>
                  </Link>
                </div>
                
                <a href="https://goo.gl/maps/G2M8btFcTneiMWVQA" target="_blank" rel="noopener noreferrer">
                  <div className="flex gap-x-4 items-center md:justify-center">                      
                    <RiMapPin2Fill
                      className="text-site-yellow text-xl"
                      aria-label="Location"
                    />
                    <span className="hover:underline">
                      470 Old Newport Boulevard, Newport Beach, CA 92663
                    </span>
                  </div>
                </a>
                <div className="flex gap-x-4 items-center md:justify-center">
                  <RiPhoneFill
                    className="text-site-yellow text-xl"
                    aria-label="telephone"
                  />
                  <a href="tel:949-645-5854" className="hover:underline">949-645-5854</a>
                </div>
                <div className="flex gap-x-4 items-center md:justify-center">
                  <IoIosMail
                    className="text-site-yellow text-xl"
                    aria-label="email"
                  />
                  <a href="mailto:info@bja-inc.com" className="hover:underline">info@bja-inc.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeContactSectionUpdate
