import * as React from "react"
import PropTypes from "prop-types"
import HeaderNew from "../header/header-new"
import HomeContactSectionUpdate from "../sections/ContactSection/home-contact-section-update"
import CommitmentSignature from "../sections/CommitmentSignature"
import FooterNewUpdate from "../footer/footer-new-update"

const LayoutUpdate = ({ children, isHome = false }) => {
  return (
    <>
      <HeaderNew isHome={isHome} />
      <div className="mt-14 lg:mt-20">
        <main>{children}</main>
      </div>
      <HomeContactSectionUpdate />
      <CommitmentSignature />
      <FooterNewUpdate />
    </>
  );
};

LayoutUpdate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutUpdate;