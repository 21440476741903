import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import SlideContent from "../../../components/sections/HomeFeaturedSection/SlideContent"
import useWindowSize from "../../../utils/useWindowSize"

const OtherPortfolioGallery = ({ uid, tag }) => {    
  const screen = useWindowSize();
  const data = useStaticQuery(graphql`
    query OtherPortfolioQuery {
      allPrismicPortfolio(sort: { fields: data___date, order: DESC }) {
        nodes {
          _previewable
          uid
          tags
          data {
            title
            preview_image {
              fluid(imgixParams: { auto: "false" }) {
                src
              }
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                imgixParams: { auto: "false" }
              )
            }
          }
        }
      }
    }
  `)

  const otherPortoflioGallery = useMemo(() => {
    if (data) {
      const otherPortfolio = data.allPrismicPortfolio;
      const result = otherPortfolio
        ? otherPortfolio.nodes
            .filter(p => p.uid !== uid && p.tags[0] === tag)
            .map(o => {
              return {
                uid: o.uid,
                tags: o.tags,
                title: o.data.title,
                image: o.data.preview_image,
              }
            })
        : []

      return result
    }
    return []
  }, [data])

  return (
    <>
      {otherPortoflioGallery && otherPortoflioGallery.length > 0 && (
        <div className="w-full flex items-center justify-center">
          <div className="w-full max-w-7xl flex flex-col items-center justify-center">
            <div className="py-12 px-4 text-3xl font-medium">{`More ${tag} Projects`}</div>
            <Swiper
              slidesPerView={screen.width <= 480 ? 1 : 2}
              spaceBetween={50}
              loop={true}
              navigation={true}
              modules={[Navigation]}
              className="portfolio-swiper"
              slideNextClass="featured-swiper-button-next"
              slidePrevClass="featured-swiper-button-prev"
            >
              {otherPortoflioGallery.map((slide, index) => {
                return (
                  <SwiperSlide
                    key={`slide-${index}`}
                    className="featured-swiper-slide"
                  >
                    {/* <div className="bg-green-300">{slide.title}</div> */}
                    <SlideContent item={slide} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      )}
    </>
  )
}

export default OtherPortfolioGallery
